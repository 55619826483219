@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
}

canvas {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 10px solid grey;
    box-shadow: 0px 0px 60px rgb(83, 83, 83);
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.col {
    flex-direction: column;
}

/* navbar */
nav {
    width: 90vw;
    margin: auto;
    margin-top: 2rem;
}

.internalTictactoe {
    padding: 2rem;
    text-align: center;
    cursor: pointer;
}