.sudoku{
    width: 100vw;
    height: 80vh;
    justify-content: space-evenly;
}
.sud{
    width: min(600px,90vw);
    height: min(600px,90vw);
    /* border: .1px solid rgba(0, 0, 0, 0.5); */
    display: grid;
    grid-template-columns: repeat(9,1fr);
    grid-template-rows: repeat(9,1fr);
    grid-gap: 4px;
    /* overflow: hidden; */
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}
.sud-in{
    width: 95%;
    height: 95%;
    /* overflow: hidden; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 4px;
    /* border: .2px solid aqua; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: .1s all ease;
}
.sud-in:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.03);
} 
.sud input{
    width: 95%;
    height: 95%;
    border: 1px solid rgba(0, 0, 0, 0.129);
    font-size: 1.5rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    font-size: 200%;
    text-align: center;
    /* transition: .2s ease; */
}
 .sud input:hover{
    background-color: rgba(128, 128, 128, 0.2);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    transform: scale(1.1);
}
.sud input:active{
    transform: scale(1) !important;
}
.sud input:focus{
    background-color: rgba(128, 128, 128, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.129) !important;
    outline: none;
    caret-color: transparent;
    transform: scale(1.1);
}
.defined{
    background-color: rgba(10, 122, 251, 0.501) !important;
}
.defined:hover, .defined:focus{
    background-color: rgba(10, 122, 251, 0.501) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}